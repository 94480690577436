import React from "react";
import { CopySection, ArchFrame, NextArrow } from "@molecules";
import { Image, Container, AnimateIn } from "@atoms";

const Opening = ({ heading, subHeading, copy, image, next }) => {
  return (
    <Container>
      <div className="flex flex-wrap gap-10 md:flex-nowrap md:gap-20">
        <CopySection
          heading={heading}
          className="text-white"
          // subHeading={subHeading}
          copy={copy}
        />
        <div className="mt-14">
          <ArchFrame caption={image.attribution}>
            <Image fill image={image} />
          </ArchFrame>
        </div>
      </div>
      <div className="mt-10 flex flex-col sm:flex-row">
        <NextArrow next={next} />
        <AnimateIn
          animateInOut
          className="ml-auto w-full px-8 text-white md:w-1/2"
        />
      </div>
    </Container>
  );
};

export default Opening;
